<template>
  <b-card>
    <template #header>
      <div class="d-flex justify-content-between align-items-center mb-2 w-100">
        <b-card-title class="title-uwu pb-1 text-warning">
          <strong>
            <feather-icon icon="UsersIcon" size="18" class="mr-1" />MEMBERS
          </strong>
        </b-card-title>
        <b-button variant="outline-success" class="btn-sm" @click="addMember">
          <feather-icon icon="PlusIcon" size="15" />
        </b-button>
      </div>
    </template>
    <template #default>
      <template v-for="(member, index) in members">
        <b-row :key="member.id" class="mb-1">
          <b-col cols="12">
            <div
              class="d-flex justify-content-between align-items-center mb-1 w-100"
            >
              <span
                ><h4>
                  MEMBER
                  {{ member.last_name ? member.last_name.name : index + 1 }}
                </h4></span
              >
              <b-button
                variant="outline-danger"
                class="btn-sm"
                @click="deleteMember(index)"
              >
                <feather-icon icon="XIcon" size="15" />
              </b-button>
            </div>
          </b-col>
          <b-col md="6" class="pb-1">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "First name" : "Nombres" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="member.firstName" style="width: 80%" />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1" v-if="country == 'US'">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >Middle name
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="member.middleName" style="width: 80%" />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "Last name" : "Apellido" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="member.lastName" style="width: 80%" />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "Email" : "Correo" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="member.email" style="width: 80%" />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "DOB" : "F. Nacimiento" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <kendo-datepicker
                v-model="member.dob"
                style="width: 80%"
                type="date"
              />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "SSN" : "DNI" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-if="country == 'US'"
                v-model="member.ssn"
                style="width: 80%"
                :v-mask="'###-##-####'"
              />
              <b-form-input
                  v-else
                  v-model="member.ssn"
                  style="width: 80%"
              />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1">
            <b-input-group>
              <b-input-group-prepend style="width: 20%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "Phone" : "Telefono" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="member.mobile"

                style="width: 80%"
                v-mask="country == 'US' ? '(###) ###-####' : false"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <address-component
          :states="states"
          :id="'member-address'"
          :index="index"
          :address-label="country == 'US' ? 'Address' : 'Direccion'"
          :city-label="country == 'US' ? 'City' : 'Ciudad'"
          :state-label="country == 'US' ? 'State' : 'Dpto'"
          :zip-code-label="country == 'US' ? 'Zip code' : 'Codigo P.'"
          :address.sync="member.maillingAddress"
          :city.sync="member.maillingCity"
          :state.sync="member.maillingState"
          :zip-code.sync="member.maillingZipCode"
          :key="member.member_id + country"
          :country="country == 'US' ? 'us' : 'pe'"
        />
      </template>
    </template>
  </b-card>
</template>
  
<script>
import { mapGetters } from "vuex";
import AddressComponent from "@/commons/AddressComponent.vue";
export default {
  components: {
    AddressComponent,
  },
  props: {
    companyData: {
      type: Object,
      default: null,
    },
    country: {
      type: String,
      default: null,
    },
    states: {
      type: Array,
      default: () => [],
    },
    members: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
    }),
    membersData() {
      return JSON.stringify(this.members);
    },
  },
  mounted() {
    //console.log(this.members, "members");
  },
  methods: {
    addMember() {
      this.members.push({
        member_id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        dob: null,
        email: null,
        ssn: null,
        documentType: 1,
        documentNumber: null,
        mobile: null,
        maillingAddress: null,
        maillingCity: null,
        maillingState: null,
        maillingZipCode: null,
        useMainAddress: false,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        anotherAddress: false,
      });
    },
    deleteMember(index) {
      this.members.splice(index, 1);
    },
    getAddressMember(address, member) {
      const mainAddress = address.street_number
        ? `${address.street_number} ${address.route}`
        : address.route;
      //console.log(mainAddress, "main");
      this.$set(member, "address", mainAddress || " ");
      this.$set(member, "maillingCity", address.locality || " ");
      this.$set(
        member,
        "maillingState",
        address.administrative_area_level_1 || ""
      );
      this.$set(member, "maillingZipCode", address.postal_code || "");
    },
  },
  watch: {
    membersData: function (val) {
      //console.log(this.members, "asd");
      this.$emit("update:members", this.members);
    },
  },
};
</script>
  
  <style scoped>
.append_especial {
  padding: 0.438rem 1rem;
  margin-bottom: 0;
  text-align: start;

  border: 1px solid rgba(255, 255, 255, 0.4);

  border-radius: 0.357rem;
  width: 12.7%;
}

.label_especial {
  width: 76.1% !important;
}

@media (max-width: 1366px) {
  .label_especial {
    width: 67.5% !important;
  }
}
</style>
  