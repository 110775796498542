<template>
  <div>
    <b-card>
      <template #header>
        <div
          class="d-flex justify-content-between align-items-center mb-2 w-100"
        >
          <b-card-title class="title-uwu text-success">
            <strong>
              <feather-icon icon="MapIcon" size="15" class="mr-1" />
              BANK INFORMATION</strong
            >
          </b-card-title>
          <b-button variant="outline-success" class="btn-sm" @click="addBank">
            <feather-icon icon="PlusIcon" size="15" />
          </b-button>
        </div>
      </template>
      <template #default>
        <b-row
          v-for="(bankAccount, index) in bankAccounts"
          :key="bankAccount.id"
          class="mb-1"
        >
          <b-col cols="12">
            <div
              class="d-flex justify-content-between align-items-center mb-1 w-100"
            >
              <span
                ><h4>
                  BANK
                  {{ bankAccount.bank ? bankAccount.bank.name : index + 1 }}
                </h4></span
              >
              <b-button
                variant="outline-danger"
                class="btn-sm"
                @click="deleteBank(index)"
              >
                <feather-icon icon="XIcon" size="15" />
              </b-button>
            </div>
          </b-col>
          <b-col md="6" class="pb-1">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%">
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <span>{{ country == "US" ? "Bank" : "Banco" }}</span>
                      <feather-icon
                        icon="PlusCircleIcon"
                        class="cursor-pointer text-success"
                        size="15"
                        @click="showCreateBankModal()"
                      />
                    </div>
                  </b-input-group-text>
                </b-input-group-prepend>
                <v-select
                  id="id2"
                  v-model="bankAccount.bank"
                  :options="banks"
                  :reduce="(val) => val"
                  label="name"
                  style="width: 78%"
                  placeholder="Select a bank ..."
                  :state="errors.length > 0 ? false : null"
                  :class="errors[0] ? 'bri-select' : ''"
                />
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col md="6" class="pb-1" v-if="country == 'PE'">
            <b-input-group>
              <b-input-group-prepend style="width: 22%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >{{ country == "US" ? "Password" : "Clave" }}
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="bankAccount.password"
                style="width: 60%"
                :type="passwordFieldType[index] === true ? 'password' : 'text'"
                :disabled="!isNew[index]"
              />
              <b-input-group-append is-text v-if="isNew[index]">
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon(index)"
                  @click="togglePasswordVisibility(index)"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%"
                    >{{ country == "US" ? "Bank Account" : "Cuenta de Banco" }}
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-model="bankAccount.bank_account"
                  style="width: 78%"
                  v-mask="['####################']"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col md="6" class="pb-1" v-if="country == 'PE'">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%"
                    > CCI 
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-model="bankAccount.cci"
                  v-mask="['###############################']"
                  style="width: 78%" 
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col md="6" class="pb-1">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%"
                    >{{ country == "US" ? "Card Number" : "Numero de tarjeta" }}
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-show="!hiddenCardNumber[index]"
                  v-model="bankAccount.card_number"
                  style="width: 60%"
                  v-mask="['####-####-####-####']"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-input
                  disabled
                  v-show="hiddenCardNumber[index]"
                  :value="MaskedCardNumbers(bankAccount.card_number)"
                />
                <b-input-group-append is-text v-if="isNew[index]">
                  <feather-icon
                    class="cursor-pointer"
                    :icon="cardNumberToggleIcon(index)"
                    @click="toggleCardNumberVisibility(index)"
                  />
                </b-input-group-append>
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col md="6" class="pb-1" v-if="country == 'PE'">
            <b-input-group>
              <b-input-group-prepend style="width: 22%">
                <b-input-group-text class="title_lable" style="width: 100%"
                  >Clave online
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="bankAccount.online_password"
                style="width: 78%"
              />
            </b-input-group>
          </b-col>
          <b-col md="6" class="pb-1" v-if="country == 'US'">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%"
                    >Type of Account
                  </b-input-group-text>
                </b-input-group-prepend>
                <v-select
                  id="id3"
                  v-model="bankAccount.type_account"
                  :options="typeAccounts"
                  :reduce="(option) => option.value"
                  label="name"
                  style="width: 78%"
                  placeholder="Select a type of account ..."
                  :state="errors.length > 0 ? false : null"
                  :class="errors[0] ? 'bri-select' : ''"
                />
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col md="6" class="pb-1">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%"
                    >Routing number
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  v-model="bankAccount.routing_number"
                  v-show="!hiddenRoutingNumber[index]"
                  style="width: 60%"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-input
                  disabled
                  v-show="hiddenRoutingNumber[index]"
                  :value="MaskedRoutingNumbers(bankAccount.routing_number)"
                />
                <b-input-group-append is-text v-if="isNew[index]">
                  <feather-icon
                    class="cursor-pointer"
                    :icon="RoutingNumberToggleIcon(index)"
                    @click="toggleRoutingNumberVisibility(index)"
                  />
                </b-input-group-append>
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col md="6" class="pb-1">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-input-group>
                <b-input-group-prepend style="width: 22%">
                  <b-input-group-text class="title_lable" style="width: 100%"
                    >Currency
                  </b-input-group-text>
                </b-input-group-prepend>
                <v-select
                  id="id4"
                  v-model="bankAccount.currency"
                  :options="currency"
                  :reduce="(option) => option.id"
                  label="value"
                  style="width: 78%"
                  placeholder="Select a type of currency ..."
                  :state="errors.length > 0 ? false : null"
                  :class="errors[0] ? 'bri-select' : ''"
                  :clearable="false"
                />
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- <show-dynamic-components
          v-if="showCustomForm"
          :slug="'company-address'"
          :id="companyData.custom_form_id ? companyData.custom_form_id : 1"
          :group_id="companyData.custom_form_group"
          :moduleId="moduleId"
          ref="companyInformationsCustomForms" 
        /> -->
      </template>
    </b-card>
    <create-bank-modal
      v-if="showCreateBank"
      @hidden="showCreateBank = false"
      @onCreated="hideCreateBankModal()"
    /> 
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompaniesService from "../../companies.service";
import CreateBankModal from "@//views/management/views/companies/companies-module/create-company/Modals/CreateBankModal.vue";
import showDynamicComponents from "@/views/management/views/settings/views/dynamic_forms/components/ShowDynamicComponents.vue";

export default {
  components: {
    CreateBankModal,
    showDynamicComponents,
  },
  props: {
    companyData: {
      type: Object,
      default: null,
    },
    country: {
      type: String,
      default: null,
    },
    bankAccounts: {
      type: Array,
      default: () => [],
    },
  },
  async mounted() {
    await this.getBanks();
    await this.initializeInputVisibility();
    this.showCustomForm = true;
  },
  data() {
    return {
      banks: [],
      showCreateBank: false,
      hiddenCardNumber: [],
      passwordFieldType: [],
      isNew: [],
      typeAccounts: [
        { name: "CHECKING", value: "CHECKING" },
        { name: "SAVING", value: "SAVING" },
      ],
      hiddenRoutingNumber: [],
      currency : [
        { id:1,value:"S/" },
        { id:2,value:"$" },
      ],
      showCustomForm:false,
    };
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    bankAccountsData() {
      return JSON.stringify(this.bankAccounts);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  methods: {
    async initializeInputVisibility() {
      this.hiddenCardNumber = new Array(this.bankAccounts.length).fill(true);
      this.hiddenRoutingNumber = new Array(this.bankAccounts.length).fill(true);
      this.passwordFieldType = new Array(this.bankAccounts.length).fill(true);
      this.isNew =
        this.currentUser.role_id == 1
          ? new Array(this.bankAccounts.length).fill(true)
          : new Array(this.bankAccounts.length).fill(false);
    },
    toggleCardNumberVisibility(index) {
      this.hiddenCardNumber[index] = !this.hiddenCardNumber[index];
      this.hiddenCardNumber = Array.from(
        { length: this.hiddenCardNumber.length },
        (_, i) => (i === index ? this.hiddenCardNumber[index] : true)
      );
    },
    toggleRoutingNumberVisibility(index) {
      this.hiddenRoutingNumber[index] = !this.hiddenRoutingNumber[index];
      this.hiddenRoutingNumber = Array.from(
        { length: this.hiddenRoutingNumber.length },
        (_, i) => (i === index ? this.hiddenRoutingNumber[index] : true)
      );
    },
    cardNumberToggleIcon(index) {
      return this.hiddenCardNumber[index] === false ? "EyeIcon" : "EyeOffIcon";
    },
    RoutingNumberToggleIcon(index) {
      return this.hiddenRoutingNumber[index] === false ? "EyeIcon" : "EyeOffIcon";
    },
    MaskedCardNumbers(card_number) {
      if (card_number) {
        return "XXXX-XXXX-XXXX-" + card_number.slice(-4);
      }
    },
    MaskedRoutingNumbers(routing_number) {
      if (routing_number) {
        return "XXXXXX" + routing_number.slice(-3);
      }
    },
    togglePasswordVisibility(index) {
      this.passwordFieldType[index] = !this.passwordFieldType[index];
      this.passwordFieldType = Array.from(
        { length: this.bankAccounts.length },
        (_, i) => (i === index ? this.passwordFieldType[index] : true)
      );
    },
    passwordToggleIcon(index) {
      return this.passwordFieldType[index] === false ? "EyeIcon" : "EyeOffIcon";
    },
    async getBanks() {
      try {
        const { data } = await CompaniesService.getBanks();
        this.banks = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    addBank() {
      this.bankAccounts.push({
        bank_id: null,
        bank_account: null,
        card_number: null,
        password: null,
        online_password: null,
        type_account: null,
        routing_number: null,
        currency:null,
        cci:null,
      });
      this.isNew.push(true);
    },
    deleteBank(index) {
      this.bankAccounts.splice(index, 1);
    },
    showCreateBankModal() {
      this.showCreateBank = true;
    },
    hideCreateBankModal() {
      this.showCreateBank = false;
      this.getBanks();
    },
  },
  watch: {
    bankAccountsData: function (val) {
      this.$emit("update:bankAccounts", this.bankAccounts);
    },
  },
};
</script>

<style scoped>
.append_especial {
  padding: 0.438rem 1rem;
  margin-bottom: 0;
  text-align: start;

  border: 1px solid rgba(255, 255, 255, 0.4);

  border-radius: 0.357rem;
  width: 12.7%;
}

.label_especial {
  width: 76.1% !important;
}

@media (max-width: 1366px) {
  .label_especial {
    width: 67.5% !important;
  }
}
</style>
