<template>
  <b-modal ref="create-bank-modal" @hidden="$emit('hidden')" centered size="sm">
    <!-- TITLE MODAL -->
    <template #modal-title>
      <span class="font-weight-bolder"> CREATE BANK </span>
    </template>
    <!-- BODY MODAL -->
    <template #default>
      <validation-observer ref="form">
        <div class="pt-2 mb-2">
          <b-row class="mb-2">
            <b-col md="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Name">
                  <b-form-input
                    v-model="bank.name"
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </template>
    <template #modal-footer>
      <b-row class="d-flex justify-content-end">
        <b-button variant="outline-success" @click="createBank()">
          CREATE
          <feather-icon icon="SaveIcon" />
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import CompaniesService from "../../../companies.service";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      bank: {
        name: null,
        created_by: null,
      },
    };
  },
  mounted() {
    this.toggleModal("create-bank-modal");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async createBank() {
      try {
        this.bank.created_by = this.currentUser.user_id;
        const valid = await this.$refs["form"].validate();
        if (!valid) return;
        await CompaniesService.saveManBank(this.bank);
        this.showSuccessSwal("Bank created successfully");
        this.$emit("onCreated");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
