<template>
  <b-modal
    v-model="onControl"
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bold"
    title="DELETED COMPONENTS"
    size="md"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
    no-close-on-esc
    ok-only
  >
    <div class="grouped-data">
      <b-card v-if="hasComponents" class="group" border-variant="primary">
        <p class="text-center">No data available.</p>
      </b-card>
      <template v-for="(group, key) in sections">
        <b-card
          v-if="group.components && group.components.length > 0"
          :key="key"
          class="group"
          border-variant="primary"
        >
          <template v-slot:header>
            <strong class="text-center h4 text-uppercase text-primary mx-auto">
              <feather-icon
                :icon="group.icon ? group.icon : ''"
                size="18"
                class="mr-1"
              />
              {{ group.title }}
            </strong>
          </template>

          <b-list-group flush>
            <b-list-group-item
              v-for="(component, index) in group.components"
              :key="index"
              class="group-item"
              :class="{ 'bg-dark': isDarkSkin }"
            >
              <div class="label-with-button d-flex justify-content-between">
                <span>{{ component.label }}</span>
                <b-button
                  variant="primary"
                  size="sm"
                  v-b-tooltip:hover
                  title="Return component"
                  @click="returnComponent(component)"
                  ><feather-icon icon="RepeatIcon"
                /></b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </template>
    </div>
  </b-modal>
</template>
<script>
import FORM_SERVICE from "@/views/management/views/settings/views/dynamic_forms/services/Form.service.js";
export default {
  props: {
    companyProp: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      dataFilter: [],
      returnData: [],
      objectFilter: {},
      sections: [],
    };
  },
  async created() {
    this.onControl = true;
    await this.GET_DATA_EDIT_CUSTON_FORM();
    this.$emit("totalComponent", this.totalComponents);
    console.log(this.totalComponents)
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    async returnComponent(item) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          `Are you sure to return the ${item.label} component?`,
          null
        );
        if (!isConfirmed) return;
        await FORM_SERVICE.removedComponent({ id: item.id, removed: 0 });
        this.GET_DATA_EDIT_CUSTON_FORM();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async GET_DATA_EDIT_CUSTON_FORM() {
      try {
        this.addPreloader();
        const { data } = await FORM_SERVICE.GET_DATA_EDIT_CUSTON_FORM({
          module_id: this.moduleId,
          form: this.companyProp.custom_form_id
            ? this.companyProp.custom_form_id
            : 1,
          group: this.companyProp.custom_form_group,
          country: this.getCountry,
          is_removed: 1,
        });
        if (data.length < 1) return;
        let forms = JSON.parse(data[0].data);
        this.sections = forms;
        forms.forEach((section) => {
          section.components.forEach((component) => {
            component.disabled = !!component.disabled;
            component.originalType = component.type;
          });
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  computed: {
    getCountry() {
      return this.$route.name == "companies-management-us" ? "US" : "PE";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    hasComponents() {
      return this.sections.every((section) => section.components.length === 0);
    },
    totalComponents() {
      return this.sections.reduce(
        (total, section) => total + section.components.length,
        0
      );
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold !important;
}
.grouped-data {
  display: flex;
  flex-wrap: wrap;
}

.group {
  flex: 1 1 300px;
  margin: 10px;
  border-radius: 5px;
}

.group h3 {
  margin-bottom: 10px;
}

.group-item {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.label-with-button {
  display: flex;
  align-items: center;
}

.label-with-button span {
  margin-right: 10px;
}
.bg-dark:not(.list-group-item-dark):hover,
.bg-dark:not(.list-group-item-dark):focus {
  background-color: transparent !important;
}
.bg-dark {
  background-color: transparent !important;
}
</style>

