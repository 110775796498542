<template>
  <b-card>
    <template v-if="title" #header>
      <b-card-title class="title-uwu text-success">
        <strong>
          <feather-icon icon="MapIcon" size="18" class="mr-1" />
          {{ title }}</strong
        >
      </b-card-title>
    </template>

    <template #default>
      <h4>{{ country == "US" ? "MAIN ADDRESS" : "DIRECCION PRINCIPAL" }}</h4>
      <address-component
        :states="stateseeuuData"
        :index="0"
        :address-label="country == 'US' ? 'Address' : 'Direccion'"
        :city-label="country == 'US' ? 'City' : 'Ciudad'"
        :state-label="country == 'US' ? 'State' : 'Dpto'"
        :zip-code-label="country == 'US' ? 'Zip code' : 'Codigo P.'"
        :address.sync="companyData.address"
        :city.sync="companyData.city"
        :state.sync="companyData.state"
        :zip-code.sync="companyData.zip_code"
        :key="country + 1"
        :country="country == 'US' ? 'us' : 'pe'"
      />
      <h4 class="mt-1">{{ country == "US" ? "MAILING ADDRESS" : "DIRECCION FISCAL" }}</h4>
      <b-row class="mt-1">
        <b-col class="mb-2" md="12">
          <b-input-group>
            <template #prepend>
              <b-skeleton v-if="!finishedLoading" class="space" type="input" />
              <b-form-checkbox
                v-if="finishedLoading"
                v-model="companyData.use_main_address"
                @change="useMainAddresFunction"
              />
              <span class="ml-1">
                {{
                  country == "US"
                    ? "Use main address?"
                    : "Usar direccion principal?"
                }}
              </span>
            </template>
          </b-input-group>
        </b-col>
      </b-row>  
      <address-component
        :states="stateseeuuData"
        :index="1"
        :address-label="country == 'US' ? 'Mailing Address' : 'Direccion'"
        :city-label="country == 'US' ? 'City' : 'Ciudad'"
        :state-label="country == 'US' ? 'State' : 'Dpto'"
        :zip-code-label="country == 'US' ? 'Zip code' : 'Codigo P.'"
        :address.sync="companyData.mailling_address"
        :city.sync="companyData.mailling_city"
        :state.sync="companyData.mailling_state"
        :zip-code.sync="companyData.mailling_zip_code"
        :key="country + 2"
        :country="country == 'US' ? 'us' : 'pe'"
      />
        <address-component v-if="country == 'US'"
          :states="stateseeuuData"
          :index="100"
          address-label="PO BOX"
          :key="country + 100"
          :address.sync="companyData.po_box"
          :city.sync="companyData.pob_city"
          :state.sync="companyData.pob_state"
          :zip-code.sync="companyData.pob_zipcode"
        />
    </template>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapGetters } from "vuex";
import AddressComponent from "@/commons/AddressComponent.vue";
export default {
  data() {
    return {
      checkIfUseMainAddress: false,
      addressData: {
        address: "12",
        city: "Lima",
        state: "",
        zipCode: "",
      },
    };
  },
  props: {
    companyData: {
      type: Object,
      default: null,
    },
    finishedLoading: {
      type: Boolean,
      default: null,
    },
    stateseeuuData: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    country: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
    }),
  },
  components: {
    vSelect,
    VueGoogleAutocomplete,
    AddressComponent,
  },
  mounted(){
    //console.log(this.companyData.useMainAddres,'dasdfa');
  },

  methods: {
    setAddress() {
      this.$set(this.addressData, "address", "Calle 1");
      this.$set(this.addressData, "city", "Lima");
      this.$set(this.addressData, "state", "Lima");
      this.$set(this.addressData, "zipCode", "15000");
    },

    useMainAddressTrue() {
      if (this.companyData.anotherAddress === true) {
        this.$refs[`address_1${this.index}`].focus();
        this.$refs[`address_2${this.index}`].focus();
      }
      if (this.companyData.anotherAddress === false) {
        this.$refs[`address_1${this.index}`].focus();
      }
    },
    useMainAddresFunction() {
      this.checkIfUseMainAddress = this.companyData.use_main_address;
      if (this.companyData.use_main_address) {
        this.companyData.mailling_address = this.companyData.address;
        this.companyData.mailling_city = this.companyData.city;
        this.companyData.mailling_state = this.companyData.state;
        this.companyData.mailling_zip_code = this.companyData.zip_code;
      } else {
        this.companyData.mailling_address = null;
        this.companyData.mailling_city = null;
        this.companyData.mailling_state = null;
        this.companyData.mailling_zip_code = null;
      }
    },
    captureChanged1(mailing_address) {
      this.companyData.address = mailing_address;
      if (this.companyData.use_main_address === true) {
        this.useMainAddresFunction();
      }
    },
    captureChanged2(mailing_address) {
      this.companyData.maillingAddress = mailing_address;
      if (this.companyData.use_main_address === true) {
        this.useMainAddresFunction();
      }
    },
    getAddressData1(address_principal1) {
      const mainAddress = address_principal1.street_number
        ? `${address_principal1.street_number} ${address_principal1.route}`
        : address_principal1.route;
      this.companyData.address = mainAddress || " ";
      this.companyData.city = address_principal1.locality
        ? address_principal1.locality
        : " ";
      this.companyData.state = address_principal1.administrative_area_level_1
        ? address_principal1.administrative_area_level_1
        : "";
      this.companyData.zipCode = address_principal1.postal_code
        ? address_principal1.postal_code
        : "";
    },
    getAddressData2(address_principal2) {
      const mainAddress = address_principal2.street_number
        ? `${address_principal2.street_number} ${address_principal2.route}`
        : address_principal2.route;

      this.companyData.maillingAddress = mainAddress || " ";
      this.companyData.maillingCity = address_principal2.locality
        ? address_principal2.locality
        : " ";
      this.companyData.maillingState =
        address_principal2.administrative_area_level_1
          ? address_principal2.administrative_area_level_1
          : "";
      this.companyData.maillingZipCode = address_principal2.postal_code
        ? address_principal2.postal_code
        : "";
    },
  },
};
</script>

<style scoped>
.append_especial {
  padding: 0.438rem 1rem;
  margin-bottom: 0;
  text-align: start;

  border: 1px solid rgba(255, 255, 255, 0.4);

  border-radius: 0.357rem;
  width: 12.7%;
}

.label_especial {
  width: 76.1% !important;
}

@media (max-width: 1366px) {
  .label_especial {
    width: 67.5% !important;
  }
}
</style>
