<template>
  <b-modal
    ref="create-company-types-modal"
    @hidden="$emit('hidden')"
    centered
    size="sm"
  >
    <!-- TITLE MODAL -->
    <template #modal-title>
      <span class="font-weight-bolder"> CREATE COMPANY TYPE </span>
    </template>
    <!-- BODY MODAL -->
    <template #default>
      <validation-observer ref="form">
        <div class="pt-2 mb-2">
          <b-row class="mb-2">
            <b-col md="12">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group label="Name">
                  <b-form-input
                    v-model="companyType.name"
                    :class="errors[0] ? 'is-invalid' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </template>
    <template #modal-footer>
      <b-row class="d-flex justify-content-end">
        <b-button variant="outline-success" @click="createCompanyType()">
          CREATE
          <feather-icon icon="SaveIcon" />
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import CompaniesService from "../../../companies.service";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      companyType: {
        name: null,
        created_by: null,
        country: null,
      },
    };
  },
  props: {
    country: {
      type: String,
      default: "US",
    },
  },
  mounted() {
    this.toggleModal("create-company-types-modal");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async createCompanyType() {
      try {
        this.companyType.created_by = this.currentUser.user_id;
        this.companyType.country = this.country;
        const valid = await this.$refs["form"].validate();
        if (!valid) return;
        await CompaniesService.createCompanyType(this.companyType);
        this.showSuccessSwal("Company type created successfully");
        this.$emit("onCreated");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
