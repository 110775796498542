<template>
  <div>
    <template>
      <validation-observer ref="form">
        <b-card class="mx-2">
          <template #header>
            <b-card-title class="title-uwu text-primary">
              <strong>
                <feather-icon icon="SettingsIcon" size="18" class="mr-1" />
                COMPANY INFORMATION

              </strong>
            </b-card-title>
          </template>
          <template #default>
            <b-row>
              <b-col md="6" class="pb-0">
                <b-row>
                  <b-col md="12" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="category"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 25%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                          >
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <span>{{
                                company.country == "US"
                                  ? "Company Type"
                                  : "Tipo Societario"
                              }}</span>
                              <feather-icon
                                icon="PlusCircleIcon"
                                class="cursor-pointer text-success"
                                size="15"
                                @click="openCreateCompanyTypeModal()"
                              />
                            </div>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <v-select
                          id="id"
                          v-model="company.company_type_id"
                          :state="errors.length > 0 ? false : null"
                          :class="errors[0] ? 'bri-select' : ''"
                          :options="companyTypes"
                          :reduce="(val) => val.id"
                          label="name"
                          style="width: 75%"
                          placeholder="Select ..."
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" class="pb-1" v-if="company.country == 'PE'">
                    <b-input-group>
                      <b-input-group-prepend style="width: 20%">
                        <b-input-group-text
                          class="title_lable"
                          style="width: 100%"
                          >Regimen T.
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <v-select
                        v-model="company.tax_regime_id"
                        label="name"
                        :reduce="(val) => val.id"
                        :options="taxRegimes"
                        style="width: 80%"
                        placeholder="Select a Regimen ..."
                      >
                        <template v-slot:option="option">
                          {{ option.name }} ({{ option.slug }})
                        </template>
                        <template #selected-option="option">
                          {{ option.name }} ({{ option.slug }})
                        </template>
                      </v-select>
                    </b-input-group>
                  </b-col>
                  <b-col md="12" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 20%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                            >{{ company.country == "US" ? "Name" : "Nombre" }}
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="company.business_name"
                          style="width: 80%"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" class="pb-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-input-group>
                        <b-input-group-prepend style="width: 20%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                            >{{
                              company.country == "US" ? "DBA" : "N. comercial"
                            }}
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="company.display_name_as"
                          style="width: 80%"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" class="pb-1" v-if="company.country == 'US'">
                    <validation-provider v-slot="{ errors }" name="fax">
                      <b-input-group>
                        <b-input-group-prepend style="width: 20%">
                          <b-input-group-text
                            class="title_lable"
                            style="width: 100%"
                            >FAX
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          v-model="company.fax"
                          style="width: 80%"
                        />
                      </b-input-group>
                      <small v-if="errors.length > 0" class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="3" class="px-1 pb-0">
                <div class="selectLogo" style="border-radius: 5px">
                  <input
                    type="file"
                    ref="file"
                    class="d-none"
                    @input="fileFill"
                    accept="image/*"
                  />
                  <img
                    class="pb-1"
                    :src="
                      !company.logo && !previewImage
                        ? 'https://programacion.net/files/article/20160819020822_image-not-found.png'
                        : previewImage
                        ? previewImage
                        : company.logo
                        ? company.logo
                        : null
                    "
                    @click="$refs.file.click()"
                    alt="Logo"
                    width="100%"
                    height="100%"
                    style="
                      height: 200px;
                      object-fit: contain;
                      cursor: pointer;
                      border-radius: 5px;
                    "
                  />
                  <div class="message_logo d-flex justify-content-between">
                    <div>
                      <span>...Upload logo</span>
                    </div>
                    <div v-if="company.logo || previewImage">
                      <feather-icon
                        icon="TrashIcon"
                        size="25"
                        class="mr-1 cursor-pointer text-danger"
                        @click="deleteLogo"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="3" class="px-1 pb-0" v-if="company.country=='PE'">
                <div class="selectLogo" style="border-radius: 5px">
                  <input
                    type="file"
                    ref="file2"
                    class="d-none"
                    @input="fileSignatureFill"
                    accept="image/*"
                  />
                  <img
                    class="pb-1"
                    :src="
                      !company.ceo_signature_route && !previewSignatureImage
                        ? 'https://programacion.net/files/article/20160819020822_image-not-found.png'
                        : previewSignatureImage
                        ? previewSignatureImage
                        : company.ceo_signature_route
                        ? company.ceo_signature_route
                        : null
                    "
                    @click="$refs.file2.click()"
                    alt="Signature"
                    width="100%"
                    height="100%"
                    style="
                      height: 200px;
                      object-fit: contain;
                      cursor: pointer;
                      border-radius: 5px;
                    "
                  />
                  <div class="message_logo d-flex justify-content-between">
                    <div>
                      <span>...Upload signature</span>
                    </div>
                    <div
                      v-if="
                        company.ceo_signature_route || previewSignatureImage
                      "
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="25"
                        class="mr-1 cursor-pointer text-danger"
                        @click="deleteSignature"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" class="pb-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="numb"
                  rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend style="width: 20%">
                      <b-input-group-text
                        class="title_lable"
                        style="width: 100%"
                        >{{ company.country == "US" ? "Phone" : "Telefono" }}
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="company.main_number"
                      v-mask="'(###)-###-####'"
                      style="width: 80%"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-col>
              <b-col md="4" class="pb-1"
                ><validation-provider
                  v-slot="{ errors }"
                  name="taxruc"
                  rules="required"
                >
                  <b-input-group>
                    <b-input-group-prepend style="width: 20%">
                      <b-input-group-text
                        class="title_lable"
                        style="width: 100%"
                        >{{ company.country == "US" ? "EIN" : "RUC" }}
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="company.ein_tax_id_ruc"
                      style="width: 80%"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-col>
              <b-col md="4" class="pb-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="emaildata"
                  rules="required|email"
                >
                  <b-input-group>
                    <b-input-group-prepend style="width: 20%">
                      <b-input-group-text
                        class="title_lable"
                        style="width: 100%"
                        >{{ company.country == "US" ? "Email" : "Correo" }}
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="company.email"
                      style="width: 80%"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="company.country == 'PE'">
                <b-row>
                  <b-col md="auto" class="d-flex align-items-center mb-1">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="company.grati_period_julio"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                    >
                      Gratification July
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="auto" class="d-flex align-items-center mb-1">
                    <b-form-checkbox
                      id="checkbox-2"
                      v-model="company.grati_period_dic"
                      name="checkbox-2"
                      value="1"
                      unchecked-value="0"
                    >
                      Gratification December
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="auto" class="d-flex align-items-center mb-1">
                    <b-form-checkbox
                      id="checkbox-3"
                      v-model="company.cts_period_nov"
                      name="checkbox-3"
                      value="1"
                      unchecked-value="0"
                    >
                      CTS November
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="auto" class="d-flex align-items-center mb-1">
                    <b-form-checkbox
                      id="checkbox-4"
                      v-model="company.cts_period_may"
                      name="checkbox-4"
                      value="1"
                      unchecked-value="0"
                    >
                      CTS May
                    </b-form-checkbox>
                  </b-col>

                  <b-col md="auto" class="d-flex align-items-center mb-1">
                    <b-form-checkbox
                      id="checkbox-5"
                      v-model="company.family_allowance"
                      name="checkbox-5"
                      value="1"
                      unchecked-value="0"
                    >
                      Family Allowance
                    </b-form-checkbox>
                  </b-col>
                  <b-col md="auto" class="d-flex align-items-center mb-1">
                    <b-form-checkbox
                      id="checkbox-6"
                      v-model="company.payroll"
                      name="checkbox-6"
                      value="1"
                      unchecked-value="0"
                    >
                      Active Payroll?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <label>CIA Documents</label>
                <drag-and-drop v-model="newFile" :files-array="newFile" />
              </b-col>
              <b-col cols="12" class="mt-1" v-if="cia">
                <div style="margin-bottom: 4px">
                  <template v-for="(item, index) in cia">
                    <b-badge variant="light-primary" :key="index">
                      <a
                        :href="item.route"
                        class="text-primary"
                        target="_blank"
                        rel="noopener"
                        v-b-tooltip.hover="item.file_name"
                        >{{ item.file_name | limitChars(20) }}</a
                      >
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="ml-1 cursor-pointer"
                        v-b-tooltip.hover="'Delete file'"
                        @click="deleteCia(item, index)"
                      />
                    </b-badge>
                  </template>
                </div>
              </b-col>
            </b-row>
            <show-dynamic-components
              v-if="showCustomForm"
              :slug="'company-information'"
              :id="company.custom_form_id ? company.custom_form_id : 1"
              :group_id="company.custom_form_group"
              :moduleId="moduleId"
              ref="companyInformationsCustomForms"
            />
          </template>
        </b-card>

        <company-address
          :key="contKeyCompany"
          :finished-loading="finishedLoading"
          :company-data="company"
          :stateseeuu-data="stateseeuuData"
          title="COMPANY ADDRESS"
          :country="company.country"
          :index="900000"
          class="mx-2"
        />
        <bank-information
          class="mx-2"
          :country="company.country"
          :bank-accounts.sync="bankInformation"
          :company-data="company"
        />
        <!-- v-model="bankInformation" -->
        <members
          class="mx-2"
          :country="company.country"
          :states="stateseeuuData"
          :members.sync="members"
        />

        <show-dynamic-forms
          v-if="showCustomForm"
          :id="company.custom_form_id ? company.custom_form_id : 1"
          :group_id="company.custom_form_group"
          :moduleId="moduleId"
          ref="dinamicForms"
          class="mx-2"
        />
      </validation-observer>
    </template>
    <create-company-type
      v-if="showCreateCompanyType"
      :country="company.country"
      @hidden="showCreateCompanyType = false"
      @onCreated="hideCreateCompanyTypeModal()"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import CompaniesService from "@/views/management/views/companies-old/companies.service";
import { mapGetters } from "vuex";
import CompanyAddress from "@/views/management/views/companies-old/companies-module/create-company/CompanyAddress.vue";
import Ripple from "vue-ripple-directive";
import VueScrollTo from "vue-scrollto";
import BankInformation from "@/views/management/views/companies-old/companies-module/create-company/BankInformation.vue";
import Members from "@/views/management/views/companies-old/companies-module/create-company/Members.vue";
import showDynamicForms from "@/views/management/views/settings/views/dynamic_forms/components/ShowDynamicForms.vue";
import CreateCompanyType from "@/views/management/views/companies-old/companies-module/create-company/Modals/CreateCompanyType.vue";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AddressComponent from "@/commons/AddressComponent.vue";
import showDynamicComponents from "@/views/management/views/settings/views/dynamic_forms/components/ShowDynamicComponents.vue";
export default {
  components: {
    Members,
    vSelect,
    CompanyAddress,
    BankInformation,
    showDynamicForms,
    CreateCompanyType,
    DragAndDrop,
    VueGoogleAutocomplete,
    AddressComponent,
    showDynamicComponents,
  },
  directives: {
    Ripple,
  },
  props: {
    companyProp: {
      type: Object,
      default: null,
      required: true,
    },
    operation: {
      type: Number,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      memberSection: false,
      previewImage: null,
      previewSignatureImage: null,
      fileSignatureToUpload: [],
      fileToUpload: [],
      previewImages: [],
      regimenType: [
        { id: "MYPE", name: "MYPE" },
        { id: "Regimen general", name: "Regimen general" },
      ],
      categoryType: [
        { id: "BUSINESS", name: "BUSINESS" },
        { id: "INDIVIDUAL", name: "INDIVIDUAL" },
      ],
      contKeyCompany: -1,
      member: [],
      finishedLoading: true,
      optionsCategory: [
        { text: "Business", value: 1 },
        { text: "Individual", value: 2 },
      ],
      optionsDocumentType: [
        { text: "DNI", value: 1 },
        { text: "Immigration Card", value: 2 },
      ],
      count: 2,
      optionsCountry: [
        { id: "US", name: "US" },
        { id: "PE", name: "PE" },
      ],

      spinnerOn: false,

      stateseeuuData: [],
      edit: false,
      prueba: {},
      companyTypes: [],
      company: {},
      bankInformation: [],
      members: [
        // {
        //   member_id: null,
        //   firstName: "",
        //   middleName: "",
        //   lastName: "",
        //   dob: null,
        //   email: null,
        //   ssn: null,
        //   documentType: 1,
        //   documentNumber: null,
        //   mobile: null,
        //   maillingAddress: null,
        //   maillingCity: null,
        //   maillingState: null,
        //   maillingZipCode: null,
        //   useMainAddress: false,
        //   address: null,
        //   city: null,
        //   state: null,
        //   zipCode: null,
        //   anotherAddress: false,
        // },
      ],
      showCustomForm: false,
      taxRegimes: [],
      showCreateCompanyType: false,
      newFile: [],
      cia: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),
    isImage() {
      const extension = ["png", "jpg", "jpeg"];
      return extension.includes(this.company.extension);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    getCountry() {
      return this.$route.name == "companies-management-us" ? "US" : "PE";
    },
  },
  async created() {
    this.moreMembers();
    this.addPreloader();
    await this.getstateseeuu();
    this.company = { ...this.companyProp };
    this.company.country = this.getCountry;
    this.members = this.companyProp.members ?? [];
    this.bankInformation = this.companyProp.bank_accounts ?? [];
    this.cia = this.companyProp.cia ?? [];
    await this.getCompanyTypes(this.company.country);
    await this.getTaxRegimes();
    this.company.imageSignature = "";
    this.removePreloader();
    this.showCustomForm = true;
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.previewImage = null;
      }
    },
    async fileFill(event) {
      // imagen, pdf
      const accept = ["image/png", "image/jpg", "image/jpeg"];
      const image = event.srcElement.files[0];

      if (image) {
        if (!accept.includes(image.type)) {
          this.showInfoSwal("Only image Allowed", "Warning");
          this.fileToUpload = [];
        } else {
          this.company.image = image.name;
          this.company.extension2 = image.name.split(".").pop();
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImage = e.target.result;
            this.company.fileImg = reader.result;
          };
          reader.readAsDataURL(image);
        }
      }
    },
    async fileSignatureFill(event) {
      const accept = ["image/png", "image/jpg", "image/jpeg"];
      const image = event.srcElement.files[0];

      if (image) {
        if (!accept.includes(image.type)) {
          this.showInfoSwal("Only image Allowed", "Warning");
          this.fileSignatureToUpload = [];
        } else {
          this.company.imageSignature = image.name;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewSignatureImage = e.target.result;
            this.company.fileSignatureImg = reader.result;
          };
          reader.readAsDataURL(image);
        }
      }
    },
    async minusMembers(index) {
      if (this.member.length > 1) {
        this.member.splice(index, 1);
      }
    },
    async openDeleteMemberCompany(data) {
      const params = {
        member_id: data.member_id,
        deleted_by: this.currentUser.user_id,
      };
      try {
        await CompaniesService.deleteMemberOfCompany(params);
      } catch (e) {
        this.showErrorSwal(e);
      }
      return [];
    },
    moreMembers() {
      this.member.push({
        member_id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        dob: null,
        email: null,
        ssn: null,
        documentType: 1,
        documentNumber: null,
        mobile: null,
        maillingAddress: null,
        maillingCity: null,
        maillingState: null,
        maillingZipCode: null,
        useMainAddress: false,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        anotherAddress: false,
      });
    },
    async getstateseeuu() {
      try {
        const data = await CompaniesService.getStatesEeuu();

        this.stateseeuuData = data.data;

        return this.stateseeuuData;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getCompanyTypes(country) {
      try {
        const { data } = await CompaniesService.getCompanyTypes({ country });
        this.companyTypes = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    afterEnter(element) {
      VueScrollTo.scrollTo(`#member_${element.dataset.index}`, 500, {
        offset: -60,
      });
    },
    async updateCompany() {
      try {
        const result = await this.$refs.form.validate();
        if (!result) {
          this.$refs.form.validate().then((success) => {
            if (!success) {
              setTimeout(() => {
                const errors = Object.entries(this.$refs.form.errors)
                  .map(([key, value]) => ({ key, value }))
                  .filter((error) => error.value.length);
                this.$refs.form.refs[errors[0].key].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            }
          });
          return;
        }

        this.addressMember();

        const resultState = await this.showConfirmSwal();

        if (resultState.isConfirmed) {
          const formData = new FormData();

          const companyInformationsCustomForms =
            this.$refs.companyInformationsCustomForms.$data.sections;
          const dataCustomForm = this.$refs.dinamicForms.$data.sections;

          dataCustomForm.push(companyInformationsCustomForms[0]);

          this.newFile.forEach((file) => {
            formData.append("files[]", file, file.name);
          });
          formData.append("company", JSON.stringify(this.company));
          formData.append("created_by", this.currentUser.user_id);
          formData.append("members", JSON.stringify(this.members));
          formData.append(
            "bankInformation",
            JSON.stringify(this.bankInformation)
          );
          formData.append("data_custom_form", JSON.stringify(dataCustomForm));
          formData.append("group", this.company.custom_form_group);
          this.addPreloader();
          const data = await CompaniesService.updateCompany(formData);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            this.$emit("closeSidebar");
            this.$emit("refreshTable");
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
    addressMember() {
      this.company.anotherAddress =
        this.company.anotherAddress === true
          ? (this.company.anotherAddress = 1)
          : (this.company.anotherAddress = 0);
      /*
      this.company.useMainAddress =
        this.company.useMainAddress === true
          ? (this.company.useMainAddress = 1)
          : (this.company.useMainAddress = 0);
*/
      const updatedMember = this.members.map((data) => {
        const updatedData = { ...data };
        updatedData.anotherAddress =
          updatedData.anotherAddress === true ? 1 : 0;
        updatedData.useMainAddress =
          updatedData.useMainAddress === true ? 1 : 0;
        return updatedData;
      });
      this.members = updatedMember;
    },
    async createCompany() {
      try {
        const result = await this.$refs.form.validate();
        if (!result) {
          this.$refs.form.validate().then((success) => {
            if (!success) {
              setTimeout(() => {
                const errors = Object.entries(this.$refs.form.errors)
                  .map(([key, value]) => ({ key, value }))
                  .filter((error) => error.value.length);
                this.$refs.form.refs[errors[0].key].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            }
          });
          return;
        }
        this.addressMember();
        if (result) {
          const formData = new FormData();

          const companyInformationsCustomForms =
            this.$refs.companyInformationsCustomForms.$data.sections;
          const dataCustomForm = this.$refs.dinamicForms.$data.sections;

          dataCustomForm.push(companyInformationsCustomForms[0]);

          this.newFile.forEach((file) => {
            formData.append("files[]", file, file.name);
          });
          formData.append("company", JSON.stringify(this.company));
          formData.append("created_by", this.currentUser.user_id);
          formData.append("members", JSON.stringify(this.members));
          formData.append(
            "bankInformation",
            JSON.stringify(this.bankInformation)
          );
          formData.append("data_custom_form", JSON.stringify(dataCustomForm));
          this.addPreloader();
          const data = await CompaniesService.saveCompanies(formData);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            this.$emit("closeSidebar");
            this.$emit("refreshTable");
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
    async getTaxRegimes() {
      try {
        const { data } = await CompaniesService.getTaxRegimes();
        this.taxRegimes = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openCreateCompanyTypeModal() {
      this.showCreateCompanyType = true;
    },
    hideCreateCompanyTypeModal() {
      this.showCreateCompanyType = false;
      this.getCompanyTypes(this.company.country);
    },
    async deleteCia(item, index) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to delete this file?"
      );

      if (confirm.isConfirmed) {
        this.cia.splice(index, 1);
        const params = {
          id_cia: item.id,
        };
        await CompaniesService.deletedCia(params);
      }
    },
    deleteLogo() {
      this.company.logo = null;
      this.previewImage = null;
      this.$refs.file.value = "";
    },
    deleteSignature() {
      this.company.ceo_signature_route = null;
      this.previewSignatureImage = null;
      this.$refs.file2.value = "";
    },
  },
  watch: {
    "company.country"(newVal, oldVal) {
      this.getCompanyTypes(newVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.deleteCiaStyle:hover {
  background: #df3d44;
  color: #fff !important;
}

.dark-layout {
  .deleteCiaStyle:hover {
    background: #df3d44;
    color: #000 !important;
  }
}

.selectLogo {
  position: relative;
}

.message_logo {
  background: rgba($color: #000000, $alpha: 1);
  height: 50px;
  font-size: 20px;
  opacity: 0;
  position: absolute;
}

.selectLogo:hover {
  //opacity: 0.5;
  .message_logo {
    opacity: 1;
    bottom: 10px;
    padding: 10px 20px;
    width: 100%;
    color: #FFF;
  }
}
</style>
